






import { Vue, Component, Prop } from "vue-property-decorator";
import Live from "@/components/live.vue";
import { liveStore } from "./store";
@Component({
  components: {
    Live,
  },
})
export default class App extends Vue {
  @Prop({ type: Function }) onReturn!: () => void;
  get uid(): string {
    return this.$route.params.uid;
  }
  get debug(): boolean {
    return liveStore.DEBUG;
  }
  get authToken(): string | null {
    return liveStore.authToken;
  }
  mounted(): void {
    window.addEventListener("message", this.setValueMessageEvent, false);
    window.parent.postMessage({ action: "isMounted", payload: null }, "*");
  }
  beforeDestroy(): void {
    window.removeEventListener("message", this.setValueMessageEvent);
  }
  setValueMessageEvent(ev: MessageEvent): void {
    if (ev.data?.action === "setAuthToken") {
      liveStore.setAuthToken(ev.data.payload);
    } else if (ev.data?.action === "setIsFollowed") {
      liveStore._isFollowed(ev.data.payload)
    }

    if(ev.data.payload?.action === "setGa4Params") {
      liveStore._setGa4Params({
        sessionId: ev.data.payload?.sessionId ?? '',
        pageTitle: ev.data.payload?.pageTitle ?? '',
        pagePath: ev.data.payload?.pagePath ?? '',
        engagementTime: ev.data.payload?.engagementTime ?? '',
      })
    }
  }
}

import { ClientRole, UID } from "agora-rtc-sdk-ng"
import pan from '@lctech-tw/f2e-doooooooog/lib/pan'
import activity from '@lctech-tw/f2e-doooooooog/lib/activity'
import type { Ga4Params } from "@/store/live";
import { getDiamondAmount } from '@/lib/services/diamond'
import postMessageInitGa4EngagementTime from '@/helpers/post-message-init-ga4-engagement-time'


const API_HOST = process.env.VUE_APP_NAHUO_API_HOST
const APP_ID = "MY_APP_ID"
const SERVICE_TOKEN = "SERVICE_TOKEN"
const WXAPP_ID = process.env.VUE_APP_WXAPP_ID
const NAHUO_VERSION = 305

export type WsMessageType = 'login' | 'connected' | 'notice' | 'erromsg' | 'userEnter' | 'tipsEnter' | 'livestatus' | 'userLeave' | 'text' | 'sent' | 'image' | 'repeal' | 'delete' | 'banned' | 'kick' | 'setManage' | 'bannedAll' | 'setting' | 'goods' | 'redpack' | 'redpackdraw' | 'coupon' | 'coupondraw' | 'communication' | 'like'

export interface LiveDetail {
    anchor: Anchor;
    detail: Detail;
    guestlist: {
        data: GuestInfo[]
    };
    userInfo: UserInfo;
    wsConfig: WsConfig;
}
interface Anchor {
    anchor_avatar: string;
    anchor_id: number;
    anchor_mobile: string;
    anchor_name: string;
    anchor_sort: number;
    anchor_status: number;
    anchor_virtual: number;
    city: string;
    country: string;
    create_time: string;
    district: string;
    fanscount: number;
    image_id: number;
    ip: string;
    latitude: string;
    longitude: string;
    province: string;
    update_time: string;
    user_id: number;
    user: {
        avatarUrl: string;
        balance: string;
        create_time: string;
        district: string;
        is_black: number;
        is_delete: number;
        mobile: number;
        nickName: string;
        open_id: string;
        refereeId: number;
        remark: string;
        update_time: string;
        user_id: number;
    }
}
interface Detail {
    agoraplayid: string;
    agoratoken: string;
    aspect_ratio_height: number;
    aspect_ratio_width: number;
    background_image: string,
    bypass: number;
    category_id: number;
    content: null
    end_time: number;
    file_id: string;
    goodscount: number;
    image: {
        extension: string;
        file_id: number;
        file_name: string;
        file_path: string;
        file_size: number;
        file_type: string;
        file_url: string;
        group_id: number;
        is_delete: number;
        is_recycle: number;
        is_user: number;
        storage: string;
        user_id: number;
    };
    image_id: number;
    is_playback: number;
    is_syn: number;
    isgift: number;
    live_coupon: string;
    live_ename: string;
    live_id: number;
    live_name: string;
    live_playbackurl: string;
    live_playurl: string;
    live_sort: number;
    live_status: string;
    live_type: number;
    maxbitrate: number;
    minbitrate: number;
    money: string;
    othersm: number;
    poster_id: number;
    screen_type: number;
    screensize: string;
    start_time: Date;
    task_id: string;
    user_id: number;
    wowza_data: string;
    wowzaapplication: string;
    wowzahost_port: string;
    wowzaid: null
    wowzapassword: string;
    wowzaphls: string;
    wowzaprimary_server: string;
    wowzaprtmp: string;
    wowzastream_name: string;
    wowzausername: string;
}
interface WsConfig {
    address: string;
    agoraappid: string;
    browser: string;
    chatavatar: number;
    chatlevel: number;
    chatuserlist: number;
    random: number;
    token: string;
    wxapp_qr_code: string;
}
interface UserInfo {
    access_token: string;
    avatarUrl: string;
    balance: string;
    city: string;
    country: string;
    district: string;
    gender: string;
    ip: string;
    is_black: number;
    is_follow: number;
    lb0: null
    lb0c: null
    lb1: null
    lb1c: null
    manage: number;
    mobile: null
    nickName: string;
    points: number;
    province: string;
    refereeId: number;
    remark: string;
    role: number;
    user_id: number;
}

export interface GuestInfo {
    avatar: string;
    banned: string;
    city: string;
    district: string;
    fd: string;
    gradename: string;
    gradeweight: string;
    ip: string;
    is_virt: string;
    kick: string;
    lasttime: string;
    lb0: string;
    lb0c: string;
    lb1: string;
    lb1c: string;
    liveid: string;
    localip: string;
    logindev: string;
    manage: string;
    mid: string;
    nickname: string;
    online: string;
    onlinetime: string;
    province: string;
    role: string;
    starttime: string
}
interface Options {
    appid: string;
    channel: string;
    token: string;
    uid: UID;
    role: ClientRole;
    liveStreamingUrl: string;
}

function postData(url: string, data: any) {
    // Default options are marked with *
    return fetch(url, {
        body: JSON.stringify(data), // must match 'Content-Type' header
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, same-origin, *omit
        headers: {
            // 'user-agent': 'Mozilla/4.0 MDN Example',
            'content-type': 'application/json'
        },
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // *client, no-referrer
    })
        .then(response => response.json()) // 輸出成 json
}

const getGlobalAccessToken = async (): Promise<string> => {
    return postData(`${API_HOST}/index.php?s=/api/user/token`, {
        wxapp_id: WXAPP_ID,
        token: SERVICE_TOKEN,
        appid: APP_ID,
        refresh: 1
    }).then(res => {
        return res.access_token
    }).catch(err => {
        return err
    })
}

const getLiveDetail = async (liveId: number, tmp: number, token: string): Promise<LiveDetail> => {
    const res = await fetch(`${API_HOST}/index.php?s=/mobile/live/detail&live_id=${liveId}&pw=&password=&ispub=0&tmp=${tmp}&role=0&pay=0&wxapp_id=${WXAPP_ID}&token=${token}&version=1&login_type=&v=${NAHUO_VERSION}`)
        .then(function (response) {
            return response.json();
        })
    return {
        anchor: res.data.anchor,
        detail: res.data.detail,
        guestlist: res.data.guestlist,
        userInfo: res.data.userInfo,
        wsConfig: res.data.wsConfig
    }
}
export interface ApiLoginResponse {
    code: number;
    data: {
        token: string;
        user_id: {
            token: string;
            user_id: number;
        },
        user_info: {
            access_token: string;
            avatarUrl: string;
            balance: string;
            gender: string;
            is_black: 0
            mobile: null
            nickName: string;
            open_id: string;
            points: 1167048
            refereeId: 0
            remark: string;
        }
    };
    msg: string;
}
// 那火token
async function nahuoApiLogin(panAccessToken: string): Promise<ApiLoginResponse> {
    const res: ApiLoginResponse = await fetch(`${API_HOST}/index.php?s=/mobile/user/apilogin/wxapp_id/${WXAPP_ID}&ak=${panAccessToken}`)
        .then(function (response) {
            return response.json();
        })
    return res
}

export interface Chat {
    banned: number;
    kick: number;
    avatar: string;
    content: string;
    fromUser: string;
    gradename: string;
    gradeweight: string;
    is_virt: string;
    liveid: string;
    manage: string;
    msgid: string;
    nickname: string;
    rtype: string;
    sendtime: string;
    toUser: string;
}
interface Pagination {
    count: number;
    page: number;
    pageCount: number;
    pageSize: number;
}
async function getChats(liveId: number, nahuoToken: string): Promise<{ data: Chat[]; page: Pagination; }> {
    const res = await fetch(`${API_HOST}/index.php?s=/mobile/Liverecords/getLiveRecordsList&live_id=${liveId}&page=-1&pagesize=20&wxapp_id=${WXAPP_ID}&token=${nahuoToken}&version=1&login_type=&v=${NAHUO_VERSION}`)
        .then(function (response) {
            return response.json();
        })
    return res.data
}

interface SendGiftRes {
    code: number;
    data: {
        money: number;
        num: number;
        tipid: number;
        tipimg: string;
        tipname: string;
    };
    msg: string;
    settings: {
        anchorname: string;
        livename: string;
    }
}

async function sendGift(params:{giftId: number, liveId: number, anchorId: number, nahuoToken: string, accessToken: string, amount: number, ga4ClientId: string, ga4Params: Ga4Params }): Promise<SendGiftRes> {
    const ga4SessionId = encodeURIComponent(params.ga4Params.sessionId)
    const ga4PagePath =  encodeURIComponent(params.ga4Params.pagePath)
    const ga4PageTitle = encodeURIComponent(params.ga4Params.pageTitle)
    const ga4EngagementTime = encodeURIComponent(params.ga4Params.engagementTime)

    const res = await fetch(`${API_HOST}/index.php?s=/mobile/gift/sendGift&gift_id=${params.giftId}&amount=${params.amount}&live_id=${params.liveId}&anchor_id=${params.anchorId}&wxapp_id=${WXAPP_ID}&token=${params.nahuoToken}&access_token=${params.accessToken}&version=1&login_type=&v=${NAHUO_VERSION}&ga4_client_id=${params.ga4ClientId}&ga4_session_id=${ga4SessionId}&ga4_page_path=${ga4PagePath}&ga4_page_title=${ga4PageTitle}&ga4_engagement_time_msec=${ga4EngagementTime}`)
        .then(function (response) {
            return response.json();
        })
    postMessageInitGa4EngagementTime()
    return res
}

export interface Audience {
    avatar: string;
    banned: string;
    is_virt: string;
    kick: string;
    liveid: string;
    manage: string;
    mid: string;
    nickname: string;
    online: string;
    role: string;
}
export interface RankAudience extends Audience {
    donate_amount: string;
}
export enum UserType {
    // 所有觀眾
    ALL = 0,
    // 在線
    ONLINE = 1,
    // 禁言
    BAN = 2,
    // 踢出
    KICK = 3,
    // 副播
    MOD = 4,
}
async function getAudienceList(liveId: number, nahuoToken: string, userType: UserType = UserType.ALL, page = 1, pageSize = 100): Promise<Audience[]> {
    const res = await fetch(`${API_HOST}/index.php?s=/mobile/live/getLiveUserList&live_id=${liveId}&page=${page}&type=${userType}&pagesize=${pageSize}&wxapp_id=${WXAPP_ID}&token=${nahuoToken}&version=1&login_type=&v=${NAHUO_VERSION}`)
        .then(function (response) {
            return response.json();
        })
    return res.data.data
}

async function getLiveUserRankList(liveId: number, page = 1, pageSize = 100): Promise<RankAudience[]> {
    const res = await fetch(`${API_HOST}/index.php?s=/mobile/live/getLiveUserRankList&wxapp_id=${WXAPP_ID}&live_id=${liveId}&page=${page}&pagesize=${pageSize}`)
        .then(function (response) {
            return response.json();
        })
    return res.data
}
export interface getGiftListResponse {
    code: number;
    data: {
        balance: number
        list: Gift[]
    };
    msg: "success"
    settings: { livename: "直播", anchorname: "主播" }
    anchorname: "主播"
    livename: "直播"
}
export interface Gift {
    animationScope: "stamp" | "full_screen";
    animationType: "svga" | "img" | "img_css";
    // 鑽石價格
    coin: number;
    // 活動標籤，左上角那顆
    event_label: string;
    gift_id: number;
    gift_name: string;
    gift_sort: number;
    image_url: string;
    jkfAnimationUrl: string;
    // 基本上跟image_url同步
    jkfImageUrl: string;
    jkfid: string;
    // 類型標籤，右上角那顆
    type_label: string;
}

async function getGiftList(nahuoToken: string): Promise<getGiftListResponse> {
    const res = await fetch(`${API_HOST}/index.php?s=/mobile/gift/lists&wxapp_id=${WXAPP_ID}&token=${nahuoToken}&version=1&login_type=&v=${NAHUO_VERSION}`)
        .then(function (response) {
            return response.json();
        })
    return res
}

interface nahuoFollowResponse {
    code: number;
    data: {
        fanscount: number
    };
    msg: "关注成功！";
    settings: { livename: "直播", anchorname: "主播" };
}
async function nahuoFollow(nahuoUid: number, nahuoToken: string): Promise<nahuoFollowResponse> {
    const res: nahuoFollowResponse = await fetch(`${API_HOST}/index.php?s=/mobile/live/subscribeAnchor&state=1&anchor_user_id=${nahuoUid}&wxapp_id=${WXAPP_ID}&token=${nahuoToken}&version=1&login_type=&v=${NAHUO_VERSION}`)
        .then(function (response) {
            return response.json();
        })
    return res
}

export interface RoomDetailResponse {
    code: number;
    msg: string;
    data: {
        detail: {
            live_id: number;
            live_status: number;
            background_image: string;
            start_time: string;
            private_time?: {
                boundary_id: number;
                create_time: string;
                // end格式是unix timestamp
                end: number;
                live_id: number;
                live_private_id: number;
                start: number;
                ticket: number;
                update_time: string;
            }
        },
        anchor: {
            user_id: number;
            anchor_name: string;
            anchor_avatar: string;
            user: {
                open_id: string;
            }
        }
    }
}

async function getRoomDetail(jkfUid: string): Promise<RoomDetailResponse> {
    const res = await fetch(`${API_HOST}/index.php?s=/mobile/live/room&wxapp_id=${WXAPP_ID}&uid=${jkfUid}`)
        .then(function (response) {
            return response.json();
        })
    return res
}

export interface GetAgoraTokenRes {
    code: number;
    msg: string;
    data: AgoraParams;
    is_private_time: boolean | null;
}
export interface AgoraParams {
    expire: number;
    channel: string;
    app_id: string;
    token: string;
}

async function getAgoraParams(liveId: number, nahuoToken?: string): Promise<GetAgoraTokenRes> {
    const res: GetAgoraTokenRes = await fetch(`${API_HOST}/index.php?s=/mobile/live/refreshPullingAgoraToken&live_id=${liveId}&token=${nahuoToken}&wxapp_id=${WXAPP_ID}`)
        .then(function (response) {
            return response.json();
        })
    return res
}

export interface BuyTicketRes {
    code: 1 | -11 | -30 | -40 | -50 | -60;
    msg: string;
    data: {
        money: number;
        num: number;
        tipid: number;
        tipimg: string;
        tipname: string;
    };
}
async function buyTicket(params: {liveId: number, nahuoToken: string, accessToken: string, ga4ClientId: string, ga4Params: Ga4Params}): Promise<BuyTicketRes> {
    const ga4SessionId = encodeURIComponent(params.ga4Params.sessionId)
    const ga4PagePath =  encodeURIComponent(params.ga4Params.pagePath)
    const ga4PageTitle = encodeURIComponent(params.ga4Params.pageTitle)
    const ga4EngagementTime = encodeURIComponent(params.ga4Params.engagementTime)

    const res: BuyTicketRes = await fetch(`${API_HOST}/index.php?s=/mobile/live/buyTicket&live_id=${params.liveId}&token=${params.nahuoToken}&wxapp_id=${WXAPP_ID}&access_token=${params.accessToken}&ga4_client_id=${params.ga4ClientId}&ga4_session_id=${ga4SessionId}&ga4_page_path=${ga4PagePath}&ga4_page_title=${ga4PageTitle}&ga4_engagement_time_msec=${ga4EngagementTime}`)
        .then(function (response) {
            return response.json();
        })
    postMessageInitGa4EngagementTime()
    return res
}

async function fakebuyTicket(liveId: number, nahuoToken: string, accessToken: string): Promise<BuyTicketRes> {
    return new Promise(resolve => {
        resolve({
            code: 1,
            msg: "",
            data: {
                money: 0,
                num: 0,
                tipid: 0,
                tipimg: "",
                tipname: ""
            }
        })
    })
}

async function canGetAgoraToken(liveId: number, nahuoToken?: string): Promise<{ code: number; msg: string; }> {
    const res: {
        code: number;
        msg: string;
    } = await fetch(`${API_HOST}/index.php?s=/mobile/live/canView&live_id=${liveId}&token=${nahuoToken}&wxapp_id=${WXAPP_ID}`)
        .then(function (response) {
            return response.json();
        })
    return res
}

export enum ManageRole {
    Audience = 0,
    Backend = 1,
    Streamer = 2,
    Moderator = 3
}

interface getLiveHistoryRes {
    code: number;
    data: {
        current_page: number;
        last_page: number;
        per_page: string;
        total: number;
        data: liveRecord[];
    };
    msg: string;
}
export interface liveRecord {
    end_time: string;
    live_id: number;
    start_time: string;
}

async function sendJkfaceActivityFreeItem(nahuoToken: string, liveId: number, anchorId: number, accessToken: string): Promise<{ code: number }> {
    const res: getLiveHistoryRes = await fetch(`${API_HOST}/index.php?s=/mobile/gift/sendItem&live_id=${liveId}&anchor_id=${anchorId}&wxapp_id=${WXAPP_ID}&token=${nahuoToken}&version=1&v=305&access_token=${accessToken}`)
        .then(function (response) {
            return response.json();
        })
    return res
}

const setJkfHost = window.dog.config.setHost

const getAccessToken = pan.api.AccessToken

const getActivityBannerList = activity.api.getActivityBannerList

const getActivityList = activity.api.getActivityList


export const clientApi = {
    getLiveDetail,
    nahuoApiLogin,
    getChats,
    sendGift,
    getAudienceList,
    getGiftList,
    nahuoFollow,
    getLiveUserRankList,
    getRoomDetail,
    getAgoraParams,
    buyTicket: buyTicket,
    canGetAgoraToken,
    sendJkfaceActivityFreeItem,
    setJkfHost,
    getAccessToken,
    getActivityBannerList,
    getActivityList,
    getDiamondAmount,

}
